@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
:root{
--color-primary: #e05c4d;
--color-secondary: #FAE8E5;
--color-third: #d9dfc4;
--color-white:#ffffff;
--color-dark:#000000;
--color-gray:#f5f5f5;
--color-gray-100: #f4f5f8;
--color-gray-200: #e3e7ed;
--color-gray-300: #d4d4d7;
--color-gray-400: #e6e6e6;
--color-gray-500: #606161;
--color-gray-600: #3f3f40;
--color-gray-700: #404041;
--color-gray-800: #3b4863;
--color-gray-900: #1c273c;
--font-primary: "Archivo", sans-serif;
}
/*==========================================================*/
body{
font-family: var(--font-primary) !important;
font-size: 0.9rem !important;
}
a{
text-decoration: none;
}
.container-fluid{
padding-left: 50px;
padding-right: 50px;
}
@media (max-width: 991px){
.container-fluid{
padding-left: 15px;
padding-right: 15px;
}
}
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
box-shadow: none !important;
outline: transparent;
}
/*==========================================================*/
.bg-primary{
background: var(--color-primary);
}
.bg-secondary{
background: var(--color-secondary);
}
.bg-third{
background: var(--color-third);
}
.tx-11{
font-size:11px;
}
.tx-12{
font-size:12px;
}
.tx-13{
font-size:13px;
}
.tx-14{
font-size:14px;
}
.tx-15{
font-size:15px;
}
.tx-16{
font-size:16px;
}
.tx-18{
font-size:18px;
}
.tx-center{
text-align: center;
}
.img-fluid{
width: 100%;
}
.line28{
line-height: 28px;
}
.linebutton{
background: transparent;
border: 0;
color: var(--color-dark);
text-decoration: underline;
}
/*==========================================================*/
.section-gap-large {
padding: 80px 0px;
}
.section-gap-medium {
padding: 60px 0px;
}
.section-gap-small {
padding: 30px 0px;
}
.sectiontop-gap-large {
padding-top: 80px;
}
.sectiontop-gap-medium {
padding-top: 60px;
}
.sectiontop-gap-small {
padding-top: 30px;
}
.sectionbottom-gap-large {
padding-bottom: 80px;
}
.sectionbottom-gap-medium {
padding-bottom: 60px;
}
.sectionbottom-gap-small {
padding-bottom: 30px;
}
@media (max-width: 991px){
.sectiontop-gap-small {
padding-top: 15px;
}
.sectionbottom-gap-small {
padding-bottom: 15px;
}
.sectiontop-gap-medium {
padding-top: 30px;
}
.sectionbottom-gap-medium {
padding-bottom: 30px;
}
}
.cols-1>* {
max-width: 100%;
flex: 0 0 100%
}
.cols-2>* {
max-width: 50%;
flex: 0 0 50%
}
.cols-3>* {
max-width: 33.3333% !important;
flex: 0 0 33.3333%
}
.cols-4>* {
max-width: 25%;
flex: 0 0 25%
}
.cols-5>* {
max-width: 20%;
flex: 0 0 20%
}
.cols-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}
.cols-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}
.cols-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
@media (min-width:1200px) {
.cols-xl-1>* {
max-width: 100%;
flex: 0 0 100%
}
.cols-xl-2>* {
max-width: 50%;
flex: 0 0 50%
}
.cols-xl-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}
.cols-xl-4>* {
max-width: 25%;
flex: 0 0 25%
}
.cols-xl-5>* {
max-width: 20%;
flex: 0 0 20%
}
.cols-xl-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}
.cols-xl-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}
.cols-xl-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}
@media (min-width:1600px) {
.cols-xxl-1>* {
max-width: 100%;
flex: 0 0 100%
}
.cols-xxl-2>* {
max-width: 50%;
flex: 0 0 50%
}
.cols-xxl-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}
.cols-xxl-4>* {
max-width: 25%;
flex: 0 0 25%
}
.cols-xxl-5>* {
max-width: 20%;
flex: 0 0 20%
}
.cols-xxl-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}
.cols-xxl-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}
.cols-xxl-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}
@media (min-width:1200px) {
.col-xl-5col {
position: relative;
flex: 0 0 20%;
max-width: 20%;
padding-right: 10px;
padding-left: 10px
}
.col-xl-5col2 {
position: relative;
flex: 0 0 40%;
max-width: 40%;
padding-right: 10px;
padding-left: 10px
}
.col-xl-5col4 {
position: relative;
flex: 0 0 80%;
max-width: 80%;
padding-right: 10px;
padding-left: 10px
}
}
.order-first {
order: -1
}
.order-last {
order: 9
}
@media (min-width:576px) {
.order-sm-auto {
order: 0
}
.order-sm-first {
order: -1
}
.order-sm-last {
order: 9
}
}
@media (min-width:768px) {
.order-md-auto {
order: 0
}
.order-md-first {
order: -1
}
.order-md-last {
order: 9
}
}
@media (min-width:992px) {
.order-lg-auto {
order: 0
}
.order-lg-first {
order: -1
}
.order-lg-last {
order: 9
}
}
@media (min-width:1200px) {
.order-xl-auto {
order: 0
}
.order-xl-first {
order: -1
}
.order-xl-last {
order: 9
}
}
/*==========================================================*/
.header{
width: 100%;
position: relative;
padding: 15px 0px;
box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.05);
background: var(--color-white);
}
.header .container,
.header .container-fluid{
display:flex;
align-items:center;
justify-content: space-between;
}
.header-center, .header-left, .header-right {
display:flex;
align-items:center
}
.navbar {
padding: 0px !important;
}
.nav-link{
text-transform: uppercase;
padding: 1rem !important;
color: var(--color-dark);
font-size: 16px !important;
font-weight: 500;
}
.header-social{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
margin-left: 20px;
gap: 10px;
}
.header-social li{
display: inline-block;
font-size: 32px;
}
.header-social li a{
color: var(--color-dark);
}
@media (max-width: 991px){
.logo img{
width: 150px;
}
.header-social li{
font-size: 22px;
} 
.sidemenu{
color: var(--color-dark);
font-size: 22px;
}
}
/*==========================================================*/
.footer {
width: 100%;
position: relative;
}
.footer .footer-top{
padding: 60px 0px;
background: var(--color-secondary);
}
.footer .footer-bottom{
padding: 30px 0px;
background: var(--color-white);
}
.footer-social{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
justify-content: center;
gap: 15px;
}
.footer-social li{
display: inline-block;
position: relative;
}
.footer-social li a{
color: var(--color-primary);
font-size: 18px;
width: 36px;
height: 36px;
border: 1px solid var(--color-primary);
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
.footer-title{
font-size: 24px;
}
.footer-logo{
display: inline-block;
}
.footer-logo img{
width: 200px;
}
.footer-list{
margin: 0;
padding: 0;
list-style: none;
}
.footer-list li{
display: inline-block;
width: 100%;
position: relative;
line-height: 30px;
}
.footer-list li a{
display: inline-block;
color: var(--color-dark);
}
.subscribe-form {
position: relative;
width:100%;
}
.subscribe-form input {
height: 47px;
background: #f5f5f5;
border: 0;
color: #b1b1b1;
padding: 10px 120px 10px 20px;
display: inline-block;
width: 100%;
font-size: 14px;
border: none;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-ms-border-radius: 5px;
-o-border-radius: 5px;
border-radius: 5px;
outline: none;
}
.subscribe-button input.button {
position: absolute;
top: 0;
right: 0;
text-transform: capitalize;
font-weight: 400;
font-size: 14px;
color: #fff;
background: var(--color-primary);
border: 0;
box-shadow: none;
padding: 0 20px;
line-height: 47px;
height: 47px;
border-radius: 0 5px 5px 0;
-webkit-transition: all 300ms linear;
-moz-transition: all 300ms linear;
-ms-transition: all 300ms linear;
-o-transition: all 300ms linear;
transition: all 300ms linear;
width: auto;
outline: none;
}
/*==========================================================*/
.listbox{
width: 550px;
margin: 0 auto;
position: relative;
}
.listbox ul{
margin: 0;
padding: 0;
list-style: none;
}
.listbox ul li{
display: flex;
align-items: center;
font-size: 16px;
gap: 10px;
position: relative;
height: 40px;
}
@media (max-width: 991px){
  .listbox{
    width: 100%;
  }
  .listbox ul li{
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    position: relative;
    height: 55px;
    line-height: 24px;
    }
}
/*==========================================================*/
.testimonial{
width: 100%;
position: relative;
background: linear-gradient(180deg, #FAE8E5 50%, #fff 50%);
}
.testimonialBox{
width: 100%;
position: relative;
}
.testimonialBox .testimonialBoxMedia{
width: 100%;
height: 350px;
overflow: hidden;
}
.testimonialBoxMedia img{
width: 100%;
height: 100%;
object-fit: cover;
}
.testimonialBox .testimonialBoxContent{
width: 100%;
position: relative;
padding-top: 15px;;
}
.testimonialBoxContent .desc{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}
/*==========================================================*/
.heroSlider{
width: 100%;
position: relative;
}
.heroSlider .media-fullscreen-wrap{
width: 100%;
height: 100%;
position: relative;
overflow: hidden;
}
.media-fullscreen-wrap .media-fullscreen-img{
width: 100%;
height: 500px;
overflow: hidden;
}
.media-fullscreen-img img{
width: 100%;
height: 100%;
object-fit: cover;
}
.heroSlider .heroSlider-content{
position: absolute;
top:0;
left: 0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
}
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/